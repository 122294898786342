.services {

  //position: relative;

  &__text {
    @include breakpoint(md) {
      padding: get-space(v, xx-large) 0;
    }
  }

  p {
    margin-bottom: get-space(v, default);
    font-size: rem-calc(16);
  }

}
