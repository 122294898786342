.form {

  &__item {
    border: none;
    color: $black-color;
    background-color: $grey-color;
    padding: rem-calc(18) rem-calc(30) rem-calc(18) rem-calc(30);
    width: 100%;
    margin: 0 0 rem-calc(13) 0;
    font-size: rem-calc(16);
    font-weight: 700;
    font-family: $page-font; 
    resize: none;
    &::placeholder {
      color: $black-color;
      font-weight: 700;
    }
  }

  .btn[type=submit] {

    &:hover{
      cursor: pointer;
      background-color: rgba($primary-color, 0.8);
    }
  }


}