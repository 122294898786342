@function map-next($map, $key, $return: 'value') {

  // Store the keys of the map as a list
  $values: map-keys($map);

  $i: 0;

  // If the Key Exists, Get the index of the key within the map and add 1 to it for the next breakpoint in the map
  @if (map-has-key($map, $key)) {
    $i: index($values, $key) + 1;
  }

  // If the key doesn't exist, or it's the last key in the map, return null
  @if ($i > length($map) or $i == 0) {
    @return null;
  }
    // Otherwise, return the value
  @else {
    @if $return == 'value' {
      @return map-get($map, nth($values, $i));
    } @else {
      @return nth($values, $i);
    }
  }
}