.box {
  font-size: rc(16);
  box-sizing: border-box;
  margin-bottom: rem-calc(30);
  text-transform: uppercase;
  line-height: 1.15;
  display: flex;
  align-items: center;
  color: $black-color;

  

  @include breakpoint(sm, down) {
    .about-us & {

      br {
        display: none;
      }
    }
  }

  .icon {
    line-height: 0;
  }

  &__text {
    text-align: left;
    @include breakpoint(sm, down) {
      width: 100%;
    }
  }
}
