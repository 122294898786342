html, body {
  font-family: $page-font;
  font-size: 100%;
  overflow-x: hidden;
}

body {
  background-color: #fff;
  color: $black-color;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  line-height: 1.5;
  font-size:   rem-calc(18);
}

* { box-sizing: border-box; }
*::after { box-sizing: border-box; }
*::before { box-sizing: border-box; }

*:focus {
  outline: none;
}

::selection {
  background-color: rgba($primary-color, 0.3);
}

::-moz-selection { // stylelint-disable-line
  background-color: rgba($primary-color, 0.3);
}

img, svg {
  max-width: 100%;
  display: inline-block;
}

a {
  text-decoration: none;
  color: $black-color;

  &:hover {
    color: $blue-color;
  }
}

p {
  margin-bottom: get-space(v, large);
}

address {
  font-style: normal;
}
