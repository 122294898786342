.section{

  margin-bottom: get-space(v, xxx-large);

  h2 {
    margin-bottom: rem-calc(30);
  }

  @include breakpoint(sm,down){
    text-align: center;
  }
}
