.popup {
  border-radius: rem-calc(5);
  position: fixed;

  @media(min-width: rem-calc(480)){
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  @media(max-width: rem-calc(479)){
    right: rem-calc(15);
    left: rem-calc(15);
    transform: translateY(-50%);
  }

  top: 50%;
  margin: rem-calc(15) 0;
  max-width: rem-calc(400);

  background-color: $white-color;
  text-align: center;
  z-index: 10001;
  display: none;

  &__close {
    position: absolute;
    right: rem-calc(15);
    top: rem-calc(15);
    color: $primary-color;
    font-size: rem-calc(24);
    background: none !important;
    border: none;
    cursor: pointer;
  }

  &__heading {
    //font-size: rem;
  }

  &__content {
    padding: rem-calc(60) rem-calc(30);
  }
}
