.already-visible,
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
}
.already-visible.fadeIn,
.animated.fadeIn {
  animation-name: fadeIn;
}

