.contact {
  color: $grey-dark-color;
  width: 100%;

  @include breakpoint(md, down){
    background: none;
    height: auto;
  }

  &__box {
    font-size: rem-calc(16);
    display: flex;
    align-items: center;
    min-height: rem-calc(60);
    width: 100%;
    background-color: $grey-color;
    margin: 0;
    padding: 0 get-space(h, large);
    font-weight: 700;
    color: $black-color;

    @include breakpoint(sm, down) {
      padding: get-space(v) 0;
    }

    > .col {
      padding: 0 get-space(h, large);

      @include breakpoint(sm, down) {
        margin-bottom: get-space(v);
      }
    }
  }

  &__person {
    margin-top: rem-calc(22);
    color: $black-color;
    text-align: left;
    @include breakpoint(sm, down) {
      text-align: center;
    }
  }

  &__img {
    margin-right: get-space(h, large);
    border-radius: rem-calc(10);
    @include breakpoint(sm, down) {
      margin-right: get-space(h);
      margin-bottom: get-space(v);
    }
  }

  &__name {
    font-size: rem-calc(24);
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: rem-calc(15);
  }
}