$white-color: #fff;
$black-color: #000;
$black-light-color: #050505;

$blue-color: #125BAA;
$blue-medium-color: #ecf2f8;
$blue-light-color: #f8fafc;

$primary-color: $blue-color;
$secondary-color: #131316;

$grey-dark-color: #A6A9AF;
$grey-color: #EDEDED;
$grey-light-color: #f1f1f1;

