/*
* Hide only visually, but have it available for screen readers: h5bp.com/v
*/

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/*
* Extends the .visuallyhidden class to allow the element to be focusable
* when navigated to via the keyboard: h5bp.com/p
*/

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}


@each $breakpoint, $size in $breakpoints {

  @include breakpoint( $breakpoint ) {

    @if $breakpoint == 'xs' {

      .hide {
        display: none;
      }

      .show {
        display: block;
      }

    } @else {

      .hide#{_get-variant-name($breakpoint, '-')}-up{
        display: none;
      }

      .show#{_get-variant-name($breakpoint, '-')}-up{
        display: block;
      }
    }
  }

  @include breakpoint( $breakpoint, down ) {

    @if $breakpoint == 'xs' {

      .hide-xs-down {
        display: none;
      }

      .show-xs-down {
        display: block;
      }

    } @else {

      .hide#{_get-variant-name($breakpoint, '-')}-down {
        display: none;
      }

      .show#{_get-variant-name($breakpoint, '-')}-down {
        display: block;
      }
    }
  }
}