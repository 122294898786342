.logo {
  &__img {
    width: rem-calc(176);
    height: rem-calc(40);

    @include breakpoint(xs, down){
       width: rem-calc(88);
       height: rem-calc(20);
     }
  }
}