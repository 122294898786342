.container{

  margin-left: auto;
  margin-right: auto;

  @each $breakpoint, $width in $wrapper-widths {
    @include breakpoint($breakpoint) {
      width: $width;
    }
  }

  @each $breakpoint, $padding in $wrapper-paddings {
    @include breakpoint($breakpoint) {
      padding-left: $padding;
      padding-right: $padding;
    }
  }
}
