footer{
  //margin-top: 100px;
  margin-bottom: rem-calc(0);
  background-color: $black-light-color;
  color: $white-color;
  text-align: center;
  font-size: rem-calc(16);
  font-weight: 700;
  height: rem-calc(77);
  padding-top: rem-calc(30);
}
