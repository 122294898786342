.overlay {
  background-color: rgba($primary-color, 0.9);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  display: none;
  padding: rem-calc(15);
}