.navigation {
  @include breakpoint(lg, down){
    display: none;
    position: fixed;
    bottom: 100%;
    top: -100%;
    left: 0;
    right: 0;
    transition: all 0.4s ease-out;
    background-color: $white-color;
    padding-top: rem-calc(30);
    overflow-y: scroll;

    &.active {
      top: 0;
      height: 100%;
      display: flex;
      justify-content: center;
      z-index: 100;
    }
  }
}

.main-menu {
  display: flex;
  align-items: center;
  @include breakpoint(lg, down){
    flex-direction: column;
    justify-content: center;
  }

  &__item {
    line-height: rem-calc(40);
    padding-left: get-space(h, large);
    padding-right: get-space(h, large);
    
    @include breakpoint(lg, down){
      padding-left: 0;
      padding-right: 0;  
      margin-bottom: rem-calc(30);
    }

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  &__link {
    color: $black-color;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    display: inline-block;

    &:hover,
    &.active {
      color: $blue-color;
      }
  }
}


#close-menu,
#res-menu {
  color: $black-color;
  font-size: rem-calc(32);
  background: none;
  padding: rem-calc(5);
  border: none;

  @include breakpoint(xs, down){
    font-size: rem-calc(16);
  }

  @include breakpoint(xl){
    display: none;
  }
}

#close-menu{
  position: absolute;
  top: rem-calc(30);
  right: rem-calc(30);
}