@function get-space($direction, $size: 'default', $local-font-size: null) {
  @if $direction == 'v' or $direction == 'vertical' {
    @if map-has-key($spaces-vertical, $size) {
      $space: map-get($spaces-vertical, $size);

      @return $space;
    } @else {
      @warn '$size is not defined';
    }
  } @else if $direction == 'h' or $direction == 'horizontal' {
    @if map-has-key($spaces-horizontal, $size) {
      $space: map-get($spaces-horizontal, $size);

      @return $space;
    } @else {
      @warn '$size is not defined';
    }
  } @else {
    @warn '$direction is not or wrongly defined';
  }
}