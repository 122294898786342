// External libs
@import '../../node_modules/breakpoint-sass/stylesheets/breakpoint';

// Functions
@import 'functions/media';
@import 'functions/rem-calc';
@import 'functions/get-variant-name';
@import 'functions/get-space';
@import 'functions/map-deep-get';
@import 'functions/map-next';

// Mixins
@import 'mixins/font-face';

// Core
@import 'core/reset';
@import 'core/variables';
@import 'core/breakpoints';
@import 'core/spacing';
@import 'core/accessibility';
@import 'core/colors';
@import 'core/fonts';
@import 'core/global';
@import 'core/page-layout';
@import 'core/typography';
@import "core/utils";

// Icons
@import "icons";

// Components
@import 'components/buttons';
@import 'components/lists';
@import 'components/tabs';
@import 'components/grid';
@import "components/popup";
@import "components/form";
@import "components/top-menu";
@import "components/main-menu";
@import "components/logo";
@import "components/box";
@import "components/animation";
@import "components/overlay";
@import "components/services-list";


// Sections
@import 'sections/intro';
@import 'sections/header';
@import 'sections/footer';
@import 'sections/content';
@import 'sections/banner';
@import 'sections/about-us';
@import 'sections/services';
@import 'sections/contact';
@import 'sections/section';

// Lib
@import 'lib/slick/index';