.banner {
  
  position: relative;
  height: rem-calc(642);

  @include breakpoint(sm, down){
    text-align: center;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    transform: translateY(-50%);
    background-color: $blue-color;
    height: rem-calc(20);
  }

  &:before {
    z-index: 1000;
    width: 51%;
    right: 0;
  }

  &:after{
    z-index: 1000;
    border-bottom-left-radius: rem-calc(15);
    border-top-left-radius: rem-calc(15);


    @include breakpoint(sm, down){
      left: rem-calc(15);
      width: 100%;
    }

    @include breakpoint(sm){
      left: 50%;
      width: rem-calc(255);
      margin-left: rem-calc(-255);
    }

    @include breakpoint(md){
      width: rem-calc(345);
      margin-left: rem-calc(-345);
    }

    @include breakpoint(lg){
      width: rem-calc(465);
      margin-left: rem-calc(-465);
    }

    @include breakpoint(xl){
      width: rem-calc(555);
      margin-left: rem-calc(-555);
    }
  }

  

  .slick {
    height: inherit;

    .container,
    .slick-list,
    .slick-track,
    &__slide {
      height: inherit;
    }

    &__slide {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &__slide-content {
      
    }

    &__pagination {
      &-wrapper {
        width: 100%;
        position: absolute;
        top: get-space(v, large);
        height: rem-calc(30);
      }
    }
    
  }

}
