.services-list {

  &__row {
    display: flex;
    align-items: center;
    padding-top: rem-calc(25);
    padding-bottom: rem-calc(25);
    border-radius: rem-calc(5);
    @include breakpoint(xs, down){
      flex-direction: column;
    }

    &:nth-child(even){
      background-color: $blue-light-color;
      .services-list__icon {
        color: $blue-color;
      }
    }

    &:nth-child(odd){
      background-color: $blue-medium-color;
      .services-list__title {
        color: $blue-color;
      }
    }

    & + & {
      margin-top: rem-calc(10);
    }
  }

  &__icon {
    flex: 0 0 auto;
    padding-left: rem-calc(30);
    padding-right: rem-calc(30);
    i {
      font-size: rem-calc(90);
      line-height: 0;
    }
  }

  &__title {
    font-size: rem-calc(24);
    text-transform: uppercase;
  }

  &__desc {
    font-size: rem-calc(18) !important;
    margin-bottom: 0 !important;
  }

  &__content {
    flex: 1 1 auto;
    padding-right: rem-calc(30);
    padding-left: rem-calc(30);
  }
}