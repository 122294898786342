.btn {
  border-radius: rem-calc(50);
  margin-bottom: get-space(v, default);
  text-transform: uppercase;
  font-weight: 700;
  color: $white-color;
  background-color: $blue-color;
  text-align: center;
  border: none;
  padding: rem-calc(20) rem-calc(64);
  display: inline-block;
  letter-spacing: 1.5px;
  font-size: rem-calc(16);
  transition: all 0.4s ease;
  
  @include breakpoint(xs, down) {
    font-size: rem-calc(14);
    padding: rem-calc(16) rem-calc(32);
  }

  &:focus,
  &.active,
  &:hover {
    color: $white-color;
    background-color: rgba($blue-color, 0.9);
  }

}
