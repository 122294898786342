// Example for including font

// @include font-face("Proxima Nova", font-files(
//                 "../fonts/customfont/ProximaNova-Bold.ttf",
//                 "../fonts/customfont/ProximaNova-Bold.woff" format('woff'),
//                 "../fonts/customfont/ProximaNova-Bold.woff2" format('woff2'),
//                 "../fonts/customfont/ProximaNova-Bold.svg" format('svg')
// ), $eot: '../fonts/customfont/ProximaNova-Bold.eot', $weight: bold);

$page-font: "PT Sans", Arial, Sans Serif;

.icon-big {
  color: $blue-color;
  font-size: rem-calc(58);
  margin-right: rem-calc(20);
  float: left;
}

.icon-mid {
  color: $blue-color;
  font-size: rem-calc(42);
  margin-right: rem-calc(20);
  float: left;
}

.icon-small {
  color: $blue-color;
  font-size: 1.5rem;
  margin-right: rem-calc(20);
  float: left;
}