h1,.h1,
h2,.h2 {
  font-weight: 900;
}

h1,.h1 {
  font-size: rem-calc(72);
  font-weight: 700;
  color: $black-color;
  letter-spacing: rem-calc(5.76);
  line-height: 1.05;
  margin-bottom: 10px;
  @include breakpoint(md, down){
    font-size: rem-calc(46);
    letter-spacing: rem-calc(0);
  }
}

h2, .h2 {
  font-size: rem-calc(48);
  line-height: 1.05;
  color: $black-color;
  letter-spacing: rem-calc(3.84);
  text-transform: uppercase;

  @include breakpoint(md, down){
    letter-spacing: rem-calc(0);
  }
}
