.text-uppercase{
  text-transform: uppercase;
}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

// Children horizontal alignment
.align-items-right { justify-content: flex-end; }
.align-items-center { justify-content: center; }
.align-items-spaced { justify-content: space-around; }
.align-items-justify { justify-content: space-between; }
// Children vertcial alignment
.align-items-top { align-items: flex-start; }
.align-items-bottom { align-items: flex-end; }
.align-items-middle { align-items: center; }

// Selft horizontal alignmnet
.align-self-left {
  margin-left: 0;
  margin-right: auto;
}

.align-self-right {
  margin-left: auto;
  margin-right: 0;
}

.align-self-center {
  margin-left: auto;
  margin-right: auto;
}

// Self vertical alignmnet
.align-self-top { align-self: flex-start; }
.align-self-bottom { align-self: flex-end; }
.align-self-middle { align-self: center; }
.align-self-stretch { align-self: stretch; }


.red{
  color: $primary-color;
}

.black{
  color: $black-color;
}

.white{
  color: $white-color;
}

.blue {
  color: $blue-color;
}


@include breakpoint(md, down){
  .hide-md-down {
    display: none;
  }
}

.error{
  //visibility: hidden;
  color: $primary-color;
  font-weight: 700;
}

.show-error{
  visibility: visible;
}

.icon__img {
  float: left;
  padding-right: rem-calc(20);

  &--small {
    float: left;
    padding-right: rem-calc(20);
    height: rem-calc(24);
  }
}

.big{

  @include breakpoint(sm, down){
    font-size:rem-calc(18);
  }

  @include breakpoint(md){
    font-size:rem-calc(22);
  }

  @include breakpoint(lg){
    font-size:rem-calc(26);
  }

}

.img {
  height: 100%;

  &--left,
  &--right {
    @include breakpoint(md) {
      position: absolute;
    }
    width: 100%;

    background-size: cover;
    background-position: 50% top;
    background-repeat: no-repeat;
  }

  &--left {
    left: 0;
  }
  &--right {
    right: 0;
  }
}