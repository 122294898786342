//$breakpoints: (
//  small: (
//    width-wrapper: rem-calc(576),
//    width-content: rem-calc(540),
//    short-name: 'sm'
//  ),
//  medium: (
//    width-wrapper: rem-calc(768),
//    width-content: rem-calc(720),
//    short-name: 'md'
//  ),
//  large: (
//    width-wrapper: rem-calc(992),
//    width-content: rem-calc(960),
//    short-name: 'lg'
//  ),
//  x-large: (
//    width-wrapper: rem-calc(1200),
//    width-content: rem-calc(1140),
//    short-name: 'xlg'
//  )
//);

$breakpoints: (
  xs: 0,
  sm: rem-calc(576),
  md: rem-calc(768),
  lg: rem-calc(992),
  xl: rem-calc(1200),
);