@font-face {
  font-family: "icons";
  src: url('../fonts/icons/icons.eot?v1');
  src: url('../fonts/icons/icons.eot?#iefix') format('eot'),
  url('../fonts/icons/icons.woff2?v1') format('woff2'),
  url('../fonts/icons/icons.woff?v1') format('woff'),
  url('../fonts/icons/icons.ttf?v1') format('truetype'),
  url('../fonts/icons/icons.svg#icons') format('svg');
}

@mixin icon-styles {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
  line-height: 1;
  display: inline-block;
}


@function icon-char($filename) {
  $char: "";

  @if $filename == accounting {
  $char: "\E001";
}
  @if $filename == close {
  $char: "\E002";
}
  @if $filename == it {
  $char: "\E003";
}
  @if $filename == launch {
  $char: "\E004";
}
  @if $filename == mail {
  $char: "\E005";
}
  @if $filename == menu {
  $char: "\E006";
}
  @if $filename == phone {
  $char: "\E007";
}
  @if $filename == pin {
  $char: "\E008";
}
  @if $filename == realestate {
  $char: "\E009";
}
  @if $filename == rent-a-car {
  $char: "\E00A";
}
  @if $filename == tax {
  $char: "\E00B";
}

@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
&:#{$insert} {
  @include icon-styles;
  content: icon-char($filename);
}
}

.icon-accounting {
  @include icon(accounting);
}
.icon-close {
  @include icon(close);
}
.icon-it {
  @include icon(it);
}
.icon-launch {
  @include icon(launch);
}
.icon-mail {
  @include icon(mail);
}
.icon-menu {
  @include icon(menu);
}
.icon-phone {
  @include icon(phone);
}
.icon-pin {
  @include icon(pin);
}
.icon-realestate {
  @include icon(realestate);
}
.icon-rent-a-car {
  @include icon(rent-a-car);
}
.icon-tax {
  @include icon(tax);
}
