.top-menu {
  /*position: fixed;
  top: 0px;
  left: 0px;*/
  background: $white-color; // pozuit premennu --opravene
  box-shadow: $box-shadow; // idealne si shadow zadefinovat ako premennu a pouzivat ju na webe --okej, taketo premenne mozem v core/variables?

  .header {
    padding: rem-calc(20);
    //width: 1170px; // prehodit na remka, ale preco si fixujes sirku ked mame na to class .container? --toto je pozostatok z minulosti, ked som to naprvy krat spravil zle, opravene
    margin: rem-calc(0) auto;

    @include breakpoint(lg, down){
      width: auto;
    }
  }

}