// ---------------------------------------------
// MIXINS AND FUNCTIONS - MEDIA
// ---------------------------------------------

// -------------------------------
// MIXINS

// CONTENTS
// Media Queries
// Legacy
// Media

// ----------------------------
// MEDIA QUERIES

@mixin breakpoint( $breakpoint, $param: false) {
  @if (
          ($breakpoint == nth(map-keys($breakpoints), 1) and not $param) or
    ( not $breakpoint ) or
    ( $breakpoint == 'default' )
  ) {
    @content;
  } @else if not $param {
    // no param, just a standard mobile first media query
    @if map-has-key( $breakpoints, $breakpoint ) {
      // breakpoint is in $breakpoints map, let's use stored value
      @media screen and (min-width: map-get($breakpoints, $breakpoint)) { @content; };
    } @else {
      // breakpoint is not in $breakpoints map, let's use custom value
      @media screen and (min-width: $breakpoint) { @content; };
    }
  } @else if (
          $param == 'down' and
    map-next($breakpoints, $breakpoint)
  ) {
    @media screen and (max-width: map-next($breakpoints, $breakpoint) - rem-calc(1)) {
      @content;
    }
  } @else if (
          $param == 'down' and
    not map_has_key($breakpoints, $breakpoint)
  ) {
    @media screen and (max-width: $breakpoint) { @content; };
  } @else if (
          $param == 'only' and
    map-next($breakpoints, $breakpoint)
  ) {
    @media screen and
    (min-width: map-get($breakpoints, $breakpoint)) and
    (max-width: map-next($breakpoints, $breakpoint) - rem-calc(1)) { @content; };
  } @else if (
          map-has-key( $breakpoints, $param) and
    map-has-key( $breakpoints, $breakpoint)
  ) {
    @media screen and
    (min-width: map-get($breakpoints, $breakpoint)) and
    (max-width: map-get($breakpoints, $param) - rem-calc(1)) { @content; };
  } @else if (
          $param == 'downfrom' and
    map-get($breakpoints, $breakpoint) > 0 and
    $breakpoint != 'xs'
  ) {
    @media screen and (max-width: map-get($breakpoints, $breakpoint) - rem-calc(1)) {
      @content;
    } // -1
  } @else if (type-of($param) == 'number') {
    @if map-has-key($breakpoints, $breakpoint) {
      // breakpoint is in $breakpoints map
      @media screen and
      (min-width: map-get($breakpoints, $breakpoint)) and
      (max-width: $param) { @content; };
    } @else {
      // breakpoint is not in $breakpoints map, let's use custom value
      @media screen and (min-width: $breakpoint) and (max-width: $param) { @content; }
    }
  } @else if (
          not ($breakpoint == nth(map-keys($breakpoints), -1) and $param == 'down') and
    not ($breakpoint == nth(map-keys($breakpoints), -1) and $param == 'only') and
    not ($breakpoint == nth(map-keys($breakpoints), 1) and $param == 'downfrom')
  ) {
    // do nothing on known edge cases. we just dont generate any code.
    @warn 'Breakpoint is not configured properly #{$breakpoint}, #{$param}';
  }
}

// ----------------------------
// LEGACY

@mixin media-w-xs() {
  @warn 'mixin media-w-xs() is deprecated. consider using breakpoint( xs, only )';

  @include breakpoint( xs, only ) { @content; };
}

@mixin media-w-s() {
  @warn 'mixin media-w-s() is deprecated. consider using breakpoint( s, down )';

  @include breakpoint( s, down ) { @content; };
}

@mixin media-w-s-only() {
  @warn 'mixin media-w-s-only() is deprecated. consider using breakpoint( s, only )';

  @include breakpoint( s, only ) { @content; };
}

@mixin media-w-m() {
  @warn 'mixin media-w-m() is deprecated. consider using breakpoint( m, down )';

  @include breakpoint( m, down ) { @content; };
}

@mixin media-w-m-only() {
  @warn 'mixin media-w-m-only() is deprecated. consider using breakpoint( m, only )';

  @include breakpoint( m, only ) { @content; };
}

@mixin media-w-l() {
  @warn 'mixin media-w-l() is deprecated. consider using breakpoint( l, down )';

  @include breakpoint( l, down ) { @content; };
}

@mixin media-w-l-only() {
  @warn 'mixin media-w-l-only() is deprecated. consider using breakpoint( l, only )';

  @include breakpoint( l, only ) { @content; };
}

@mixin media-w-xl() {
  @warn 'mixin media-w-xl() is deprecated. consider using breakpoint( xlarge, down )';

  @include breakpoint( xlarge ) { @content; };
}

@mixin media($feature, $value) {
  @media screen and ($feature: $value) { @content; }
}
